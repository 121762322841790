
import { Options, Vue } from 'vue-class-component';
import { ChatNodeChatClient } from '@/services/Services';
import * as OM from '@/Model';

@Options({})
export default class chatNodeList extends Vue {

    items: OM.ChatRoomListVM[] = [];

    created(){
        ChatNodeChatClient.getAllChatRooms()
        .then(x => {
            this.items = x;
        })
    }

}
